<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">
      <div class="col-md-12">
        <div class="card ">
          <div class="card-body">
            <div class="row title-row">
              <div class="col-xl-6 col-lg-6 col-md-6">
                <h4 class="card-title mb-4">Ticket List</h4>
              </div>
              <!-- <div class="col-xl-6 col-lg-6 col-md-6">
                                <div class="add-btn-box">
                                    <span v-b-modal.modal-center @click="form = {}" class="addbtn">Add
                                        Broker</span>
                                </div>
                            </div> -->
            </div>

            <simplebar style="max-height: 600px">
              <div class="table-responsive">
                <b-table
                  :items="myProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :busy="loading"
                  ref="table"
                  show-empty
                >
                  <template #empty>
                    <div class="d-flex justify-content-center mb-3">
                      No Record Found
                    </div>
                  </template>

                  <template #table-busy>
                    <div class="text-center text-info my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <!-- <template v-slot:cell(id)="data">
                    <div v-if="data.item.id">
                      {{ data.item.id }}
                    </div>
                  </template> -->

                  <template v-slot:cell(title)="data">
                    <div v-if="data.item.title">
                      {{ data.item.title }}
                    </div>
                  </template>

                  <template v-slot:cell(category)="data">
                    <div v-if="data.item.category">
                      {{ data.item.category.name }}
                    </div>
                  </template>

                  <template v-slot:cell(created_at)="data">
                    <div v-if="data.item.created_at">
                      {{ new Date(data.item.created_at).toLocaleDateString() }}
                    </div>
                  </template>

                  <template v-slot:cell(author_name)="data">
                    <div v-if="data.item.author_name">
                      {{ data.item.author_name }}
                    </div>
                  </template>

                  <template v-slot:cell(author_email)="data">
                    <div v-if="data.item.author_email">
                      {{ data.item.author_email }}
                    </div>
                  </template>

                  <template v-slot:cell(priority)="data">
                    <div
                      class="text-center py-1 px-3 rounded"
                      v-if="data.item.priority"
                      :style="priority(data.item.priority)"
                    >
                      <span class="fw-bold">{{ data.item.priority }}</span>
                    </div>
                  </template>

                  <!-- Status section -->
                  <template v-slot:cell(status)="data">
                    <div
                      class="text-center py-1 px-3 rounded fw-bold"
                      v-if="data.item.status"
                      :style="statusColor(data.item.status)"
                    >
                      {{ data.item.status }}
                    </div>
                  </template>

                  <template v-slot:cell(action)="data">
                    <ul class="list-group list-group-horizontal">
                      <li class="list-group-item bg-transparent border-0 pe-0">
                        <span v-b-modal.modal-center @click="form = data.item">
                          <i class="far fa-edit text-primary action"></i>
                        </span>
                      </li>
                      <li class="list-group-item bg-transparent border-0 ps-2">
                        <span
                          ><router-link
                            :to="'/support/view-ticket/' + data.item.id"
                          >
                            <i
                              class="far fa-eye text-dark action"
                            ></i> </router-link
                        ></span>
                      </li>
                    </ul>
                  </template>
                </b-table>
              </div>
              <div class="row m-0">
                <div class="col p-0">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-end"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </simplebar>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal for assigning token to vendor -->
    <b-modal
      id="modal-center"
      size="xl"
      centered
      title="Edit Priority and Status"
      title-class="font-18"
      hide-footer
      class="padding_modal"
      ref="vuemodal"
      @hidden="onHidden()"
    >
      <b-form @submit="onSubmit($event, form.id)">
        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Select Priority</label
          >
          <multiselect
            v-model="form.priority"
            :options="selectPriority"
          ></multiselect>
        </div>

        <div class="mb-3">
          <label for="exampleFormControlTextarea1" class="form-label"
            >Select Status</label
          >
          <multiselect
            v-model="form.status"
            :options="selectStatus"
          ></multiselect>
        </div>

        <div class="mt-5 text-end">
          <button type="submit" class="btn btn-outline-info">
            <span v-if="this.formloading">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </span>
            <span v-else> Submit </span>
          </button>
        </div>
      </b-form>
    </b-modal>

    <!-- end row -->
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import simplebar from "simplebar-vue";
import ApiClass from "../../api/api";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";

/**
 * Lending component
 */
export default {
  page: {
    title: "Ticket List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    simplebar,
    Multiselect,
  },
  data() {
    return {
      title: "Ticket List",
      items: [
        {
          text: "Ticket List",
          href: "#",
        },
        {
          text: "Support",
          active: true,
        },
      ],
      loading: false,
      formloading: false,
      selectPriority: ["low", "medium", "high"],
      selectStatus: ["open", "close", "inprogress"],
      // selectPriority: [
      //     { "key":"low" , "value" : "Low"},
      //     { "key":"medium" , "value" : "Medium"},
      //     { "key":"high" , "value" : "High"},
      // ],
      // selectStatus:[
      //     { "key":"open" , "value" : "Open"},
      //     { "key":"close" , "value" : "Close"},
      // ],
      form: {},
      totalRows: 0,
      currentRowData: "",
      currentPage: 1,
      perPage: 5,
      sortBy: "value",
      sortDesc: false,
      fields: [
        // {
        //   key: "id",
        //   value: "id",
        //   sortable: false,
        // },
        {
          key: "title",
          value: "title",
          sortable: false,
        },
        {
          key: "content",
          value: "content",
          sortable: false,
        },
        {
          key: "category",
          value: "category",
          sortable: false,
        },
        {
          key: "created_at",
          value: "created_at",
          sortable: false,
        },
        {
          key: "author_name",
          value: "author_name",
          sortable: false,
        },
        {
          key: "author_email",
          value: "author_email",
          sortable: false,
        },
        {
          key: "priority",
          value: "priority",
          sortable: false,
        },
        {
          key: "status",
          value: "status",
          sortable: false,
        },
        {
          key: "action",
          value: "action",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    onHidden() {
      this.$refs.table.refresh();
    },
    priority(change) {
      if (change == "low") {
        return {
          backgroundColor: "#b3e5fc",
          color: "#23547b",
        };
      }
      if (change == "medium") {
        return {
          backgroundColor: "#ffd8b2",
          color: "#805b36",
        };
      } else {
        return {
          backgroundColor: "#ffcdd2",
          color: "#c63737",
        };
      }
    },
    statusColor(change) {
      if (change == "open") {
        return {
          color: "#256029",
          backgroundColor: "#c8e6c9",
        };
      } else if (change == "close") {
        return {
          color: "#c63737",
          backgroundColor: "#ffcdd2",
        };
      } else {
        return {
          color: "#805b36",
          backgroundColor: "#ffd8b2",
        };
      }
    },

    async onSubmit(event, id) {
      event.preventDefault();
      this.formloading = true;

      var param = {
        priority: this.form.priority,
        status: this.form.status,
      };

      var result = await ApiClass.updateFormRequest(
        "admin/ticket/update/" + id,
        true,
        param
      );
      console.log("here=", result);

      if (result.data.status_code == "1") {
        this.formloading = false;
        Swal.fire({
          position: "center",
          icon: "success",
          title: result.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
        this.$refs.table.refresh();
        this.$root.$emit("bv::hide::modal", "modal-center");
      } else {
        this.formloading = false;
        Swal.fire({
          position: "center",
          icon: "error",
          title: result.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
    deletebroker(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          ApiClass.deleteRequest("admin/broker/delete/" + id, true);
          Swal.fire("Deleted!", "Broker has been deleted.", "success");
          this.$refs.table.refresh();
        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {
          Swal.fire("Cancelled", "Broker still in our system. :)", "error");
        }
      });
    },
    async myProvider(ctx, callback) {
      this.loading = !this.loading;
     await ApiClass.getRequest("admin/ticket/get", true)
        .then((res) => {
          console.log('res=', res);

          if (res.data.status_code == 1) {
            this.loading = !this.loading;
            const items = res.data.data.data
            // this.currentPage = res.data.data ? res.data.data.current_page : '';
            // this.totalRows = res.data.data ? res.data.data.total : '';
        

            callback(items);
          }
        })
        .bind(this);
    },
    onclose() {
      this.$root.$emit("bv::hide::modal", "modal-center");
    },
  },
};
</script>

<style scoped>
.action {
  font-weight: 600;
  cursor: pointer;
}

.add-btn-box {
  text-align: right;
}

.add-btn-box span.addbtn {
  background-color: #556ee6;
  color: #fff;
  padding: 10px 10px;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 13px;
  font-weight: 400;
}
</style>